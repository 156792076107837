/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import { features, parking_features, parking_data } from "../util/const"
import { GoLocation } from "react-icons/go"
import { BiBus } from "react-icons/bi"
import { Container, Box, Button } from "theme-ui"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SearchFormParking from "../components/SearchFormParking"

const styles = {
  "article blockquote": {
    "background-color": "cardBg",
  },
  pagination: {
    a: {
      color: "muted",
      "&.is-active": {
        color: "text",
      },
      "&:hover": {
        color: "text",
      },
    },
  },
}

const Pagination = props => (
  <div className="pagination -post" sx={styles.pagination}>
    <ul>
      {props.previous &&
        props.previous.frontmatter.template === "parking-post" && (
          <li>
            <Link to={props.previous.frontmatter.slug} rel="prev">
              <p
                sx={{
                  color: "muted",
                }}
              >
                <span className="icon -left">
                  <RiArrowLeftLine />
                </span>{" "}
                Poprzedni
              </p>
              <span className="page-title">
                {props.previous.frontmatter.title}
              </span>
            </Link>
          </li>
        )}
      {props.next && props.next.frontmatter.template === "parking-post" && (
        <li>
          <Link to={props.next.frontmatter.slug} rel="next">
            <p
              sx={{
                color: "muted",
              }}
            >
              Następny{" "}
              <span className="icon -right">
                <RiArrowRightLine />
              </span>
            </p>
            <span className="page-title">{props.next.frontmatter.title}</span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

const Post = ({ data, pageContext }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""
  const { previous, next, doNotShowOnMainPage } = pageContext

  let props = {
    previous,
    next,
  }
  const parking =
    parking_data[process.env.GATSBY_PARKING_SLUG] &&
    parking_data[process.env.GATSBY_PARKING_SLUG][frontmatter.slug] != null &&
    parking_data[process.env.GATSBY_PARKING_SLUG][frontmatter.slug]
  const fromAirport = parking?.fromAirport ?? ""
  const transfer = parking?.transfer ?? ""
  const excludedFeatures =
    parking_data[process.env.GATSBY_PARKING_SLUG]?.excludedFeatures ?? []
  const customFeatures = parking?.customFeatures ?? []

  return (
    <Layout className="page">
      <Seo
        title={`${frontmatter.title} | Parking ${process.env.GATSBY_AIRPORT_NAME} `}
        // title={
        //   frontmatter.title.length > 100
        //     ? frontmatter.title
        //     : `${
        //         process.env.GATSBY_TITLE_PREFIX
        //           ? process.env.GATSBY_TITLE_PREFIX + " "
        //           : ""
        //       }` +
        //       frontmatter.title +
        //       ` ${process.env.GATSBY_AIRPORT_NAME} | Rezerwacja online`
        // }
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        image={
          frontmatter.featuredImage.childImageSharp.gatsbyImageData.images
            .fallback.src
        }
        article={false}
      />
      <article className="blog-post">
        <header className="featured-banner">
          <section className="article-header">
            <h1>{frontmatter.title}</h1>
            {/* <time sx={{ color: "muted" }}>{""}</time> */}
          </section>
          {Image ? (
            <GatsbyImage
              image={Image}
              alt={frontmatter.title + " - zdjęcie parkingu"}
              className="featured-image"
            />
          ) : (
            ""
          )}
        </header>
        <Container p={4} className={"parking-feature-container"}>
          {Object.keys(features)
            .filter(feat => !excludedFeatures.includes(features[feat]))
            .map((feature, index) => (
              <div key={index} className={"parking-feature"}>
                {parking_features[frontmatter.slug] != null &&
                parking_features[frontmatter.slug].includes(features[feature])
                  ? "✅  "
                  : "❌  "}
                {features[feature]}
              </div>
            ))}
          {customFeatures?.length === 0
            ? null
            : customFeatures.map((feat, index) => (
                <div key={"customfeatures" + index}>{feat}</div>
              ))}
        </Container>
        <div className="post-content-location text-center">
          <span className="icon">
            <GoLocation />
            <b> {fromAirport.toString().padEnd(3, ".0")} km </b>
            odległość od lotniska
          </span>
          <span className="icon transfer">
            <BiBus />
            <b> {transfer ? transfer : "0"} min </b>
            transfer na lotnisko
          </span>
        </div>
        {doNotShowOnMainPage ? (
          <div className="text-center">
            <h2 style={{ color: "red" }}> Brak możliwości rezerwacji</h2>
            <Link to="/">
              <Button py={2} px={5}>
                {"Idź do wyszukiwarki parkingów"}
              </Button>
            </Link>
          </div>
        ) : (
          <Box
            margin={4}
            padding={2}
            style={{ boxShadow: "0px 0px 11px 8px #f5f3f3" }}
          >
            <SearchFormParking isParkVia={frontmatter.isParkVia} />
          </Box>
        )}

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div className="text-center">
          <Link to="/">
            <Button py={2} px={5}>
              {"Idź do wyszukiwarki parkingów"}
            </Button>
          </Link>
        </div>
      </article>
      {(previous || next) && <Pagination {...props} />}
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query ParkingPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        slug
        title
        description
        doNotShowOnMainPage
        isParkVia
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
